







































import HeaderUserProfile from '@/domains/agent/layouts/components/HeaderUserProfile.vue';
import MainMenu from './components/MainMenu.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'SharedLayoutMain',
  components: {
    MainMenu,
    HeaderUserProfile,
  },
})
export default class AdminLayoutDefault extends Vue {
  leftDrawer = true;
}
