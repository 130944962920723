














































import HeaderNotifications from './components/HeaderNotifications.vue';
import HeaderQuickNav from './components/HeaderQuickNav.vue';
import HeaderSearch from './components/HeaderSearch.vue';
import HeaderUserProfile from './components/HeaderUserProfile.vue';
import MainMenu from './components/MainMenu.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { User } from '@/models';

@Component({
  name: 'SharedLayoutMain',
  components: {
    HeaderSearch,
    HeaderNotifications,
    MainMenu,
    HeaderQuickNav,
    HeaderUserProfile,
  },
})
export default class AgentLayoutDefault extends Vue {
  leftDrawer = true;

  get agent(): User {
    return this.$storage.auth.getters.user();
  }
}
