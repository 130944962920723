import { Dashboard } from './dashboard';
import { RouteConfig } from 'vue-router';

type Module = 'Dashboard';

export const modules = {
  Dashboard,
};

export const getModuleRoutes = (module: Module): Array<RouteConfig> => {
  const importedModule = modules[module];
  return importedModule.routes;
};
