import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Admin, Agent, Shared } from '../domains';
import { User } from '@/models';
import { store } from '../main';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...Shared.Modules.getModuleRoutes('Auth'),
  ...Admin.Modules.getModuleRoutes('Dashboard'),
  ...Agent.Modules.getModuleRoutes('Dashboard'),
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Check auth and set auth in store if required
  await store.$storage.auth.actions.checkAuth();

  // // Check if any matched routes require auth
  // const requiresAuth = to.matched.some(
  //   (route) => route.meta.requiresAuth != false
  // );

  // // Get the authed stat=
  // const authed = store.$storage.auth.getters.isAuthed();
  // if (requiresAuth != false && !authed) {
  //   next({ name: 'login' });
  // } else {
  //   next();
  // }

  // Check if any matched routes require auth
  const requiresAuth = to.matched.some((route) => {
    return route.meta.requiresAuth != false;
  });

  // Get the authed state
  const authed = store.$storage.auth.getters.isAuthed();

  // Doesn't required auth. Not authed.
  // Redirect to login so user can auth
  if (requiresAuth && !authed) {
    next({ name: 'login' });
  }

  // Requires auth. Not authed.
  // Continue as page does not need auth
  if (requiresAuth == false && !authed) {
    next();
  }

  // Required auth. Authed.
  // Check role
  if (requiresAuth && authed) {
    const user = store.$storage.auth.getters.user();
    if (to.meta.role && checkRole(user, to.meta.role)) {
      // route role matches user role so continue
      next();
    } else {
      User.logout();
      next({ name: 'login' });
    }
  }

  next();
});

function checkRole(user: User, role: string | string[]): boolean {
  if (user.isSuperAdmin) {
    return true;
  }

  let allowed = false;
  if (Array.isArray(role)) {
    allowed = role.includes(user.data.role);
  } else {
    allowed = user.data.role == role;
  }

  return allowed;
}

export default router;
