var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex pb-3"},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.agencies,"hide-details":"","label":"Select Agency","placeholder":"Select Agency","item-text":"data.name","dense":"","solo":"","return-object":"","clearable":""},model:{value:(_vm.agency),callback:function ($$v) {_vm.agency=$$v},expression:"agency"}}),_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.agents,"hide-details":"","label":"Select Agent","placeholder":"Select Agent","item-text":"name","dense":"","solo":"","return-object":"","clearable":""},model:{value:(_vm.agent),callback:function ($$v) {_vm.agent=$$v},expression:"agent"}}),_c('v-spacer'),_c('v-btn',{staticClass:"ml-sm-2 my-1",on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_vm._v(_vm._s(_vm.showFilters ? 'Hide' : 'Show')+" Filters ("+_vm._s(_vm.activeFilters)+")")])],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}]},[_c('v-row',{staticClass:"pb-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('filter-expiry',{attrs:{"label":"Booked Date"},model:{value:(_vm.filters.bookedDate),callback:function ($$v) {_vm.$set(_vm.filters, "bookedDate", $$v)},expression:"filters.bookedDate"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('filter-booking-reason',{model:{value:(_vm.filters.reasons),callback:function ($$v) {_vm.$set(_vm.filters, "reasons", $$v)},expression:"filters.reasons"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('filter-booking-status',{model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)],1)],1)]),_c('v-card',[_c('v-data-table',{staticClass:"flex-grow-1",attrs:{"loading":_vm.loading,"items":_vm.bookings,"headers":_vm.headers,"items-per-page":50,"footer-props":{
        itemsPerPageOptions: [-1, 50, 100, 250],
      },"options":_vm.options,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.viewDetails},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{ref:"search",staticClass:"mx-1 hidden-xs-only",attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search...","hide-details":"","solo":"","flat":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.address",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.property.streetAddress)+" ")]}},{key:"item.suburb",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.property.suburb)+" ")]}},{key:"item.reason",fn:function(ref){
      var item = ref.item;
return _vm._l((item.data.reasons),function(reason){return _c('v-chip',{key:reason,staticClass:"mr-1",attrs:{"label":"","color":item.reasonColour(reason).colour,"dark":item.reasonColour(reason).dark}},[_vm._v(_vm._s(reason))])})}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [(item.date)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date)))]):_c('span',[_vm._v("...")])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [(item.data.status)?_c('v-chip',{attrs:{"label":""}},[_vm._v(" "+_vm._s(item.data.status))]):_c('span',[_vm._v("...")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewDetails(item)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"650px"},model:{value:(_vm.viewingDetails),callback:function ($$v) {_vm.viewingDetails=$$v},expression:"viewingDetails"}},[_c('booking-details',{attrs:{"booking":_vm.booking},on:{"close":function($event){return _vm.resetBooking()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }