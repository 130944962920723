import { APIService } from '../api';
import { User, UserData } from '@/models';

export type LoginCredentials = {
  email: string;
  password: string;
};

export type LoginRepsonse = {
  email: string;
  name: string;
  firstName?: string;
  lastName?: string;
};

export class AuthService {
  constructor(private api: APIService) {}

  async getCsrfCookie(): Promise<void> {
    await this.api.get('/sanctum/csrf-cookie');
  }

  async login(credentials: LoginCredentials): Promise<User> {
    await this.getCsrfCookie();
    await this.api.post<UserData>('/login', credentials);

    const user = await this.api.get('/user');
    return new User(user);
  }

  async logout(): Promise<void> {
    const response = await this.api.post<void>('/logout', null, {
      scoped: false,
    });
    return response;
  }
}
