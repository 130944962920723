



















































/* eslint-disable @typescript-eslint/no-explicit-any */
import ComplianceChart from '@/domains/agent/modules/dashboard/components/ComplianceChart.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Agency, User, UserData } from '@/models';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {
    ComplianceChart,
  },
})
export default class DashboardPage extends Vue {
  data: any[] = [];
  agents: User[] | null = [];
  loading = false;
  target: 'agent' | 'agency' = 'agent';
  agent: User | null = null;
  agencies: Agency[] = [];
  agency: Agency | null = null;

  headers = [
    { text: 'Status', value: 'status' },
    { text: 'Count', value: 'count' },
  ];

  get user(): User {
    return this.$storage.auth.getters.user();
  }

  async filter(item: any): Promise<void> {
    this.$router.push({
      name: 'admin.properties',
      query: { status: item.status },
    });
  }

  async mounted(): Promise<void> {
    await this.fetchAgencies();

    if (this.user.settings?.agency) {
      this.agency =
        this.agencies.find(
          (agency) => agency.id == this.user.settings?.agency
        ) ?? null;
    }

    await this.fetchAgents();

    if (this.user.settings?.agent) {
      this.agent =
        this.agents?.find(
          (agent) => agent.data.id == this.user.settings?.agent
        ) ?? null;
    }

    await this.getPropertyMetrics();
  }

  async getPropertyMetrics(): Promise<void> {
    this.loading = true;
    if (!this.agency && !this.agent) {
      this.data = await this.$services.api.get('/properties/metrics');
    } else if (this.agency && !this.agent) {
      this.data = await this.$services.api.get(
        `/agencies/${this.agency.data.id}/properties/metrics`
      );
    } else if (this.agent) {
      this.data = await this.$services.api.get(
        `/agents/${this.agent.data.id}/properties/metrics`
      );
    }

    this.loading = false;
  }

  async fetchAgencies(): Promise<void> {
    this.agencies = await Agency.get();
  }

  async fetchAgents(): Promise<void> {
    let agents;
    if (this.agency) {
      agents = await this.$services.api.get(
        `/agencies/${this.agency.data.id}/agents`
      );
    } else {
      agents = await this.$services.api.get('/agents');
    }

    this.agents = agents
      .map((data: UserData) => new User(data))
      .filter((data: User) => data.isPropertyManager == true);
  }

  @Watch('target')
  onTargetChange(newVal: string): void {
    if (newVal == 'agent') {
      this.agent = null;
    }

    this.getPropertyMetrics();
  }

  @Watch('agent', { deep: true })
  onAgentChanged(): void {
    this.getPropertyMetrics();
    this.user.saveSelectedAgent(this.agent);
  }

  @Watch('agency', { deep: true })
  onAgencyChanged(): void {
    this.getPropertyMetrics();
    this.fetchAgents();
    this.user.saveSelectedAgency(this.agency);
    this.agent = null;
  }
}
