import Vue from 'vue';
import { format, isDate, parseISO } from 'date-fns';

Vue.filter(
  'formatDate',
  (value: Date | string, filterFormat = 'dd/MM/yyyy') => {
    if (!value) {
      return null;
    }
    let date: Date;
    if (!isDate(value)) {
      date = parseISO(value as string);
    } else {
      date = value as Date;
    }
    return format(date, filterFormat);
  }
);
