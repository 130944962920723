






















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { User } from '@/models';

@Component({
  name: 'ResetPasswordForm',
})
export default class ResetPasswordForm extends Vue {
  @Prop(String) readonly token!: string;

  loading = false;
  reset = false;

  message: string | null = null;
  showPassword = false;

  valid = false;

  credentials = {
    email: '',
    password: '',
    password_confirmation: '',
    token: '',
  };

  rules = {
    email: [(v: string): string | boolean => !!v || 'Email is required'],
    password: [
      (v: string): string | boolean => !!v || 'Password is required',
      (v: string): string | boolean =>
        v.length >= 6 || 'Password must be at least 6 characters',
    ],
  };

  async submit(): Promise<void> {
    this.loading = true;
    this.credentials.password_confirmation = this.credentials.password;
    this.credentials.token = this.token;
    const response = await User.resetPassword(this.credentials);
    if (response) {
      this.message = response.message;
      this.reset = true;
    } else {
      this.loading = false;
    }
    this.loading = false;
  }

  mounted(): void {
    if (!this.token) {
      this.$router.push({ name: 'login' });
    }
  }
}
