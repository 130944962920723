

















































































































































import Component from 'vue-class-component';
import FilterCompliant from '../components/FilterCompliant.vue';
import FilterExpiry from '../components/FilterExpiry.vue';
import Vue from 'vue';
import { Property } from '@/models/Property';
import { PropertyDetails } from '../components';
import { User, UserData } from '@/models';
import { Watch } from 'vue-property-decorator';
import { getTarget, setTarget } from '@/store/settings';

@Component({
  components: {
    PropertyDetails,
    FilterExpiry,
    FilterCompliant,
  },
})
export default class PropertiesPage extends Vue {
  agents: User[] | null = [];
  agent: User | null = null;
  viewingDetails = false;
  property: Property | null = null;
  loading = false;
  showFilters = true;

  //   target: 'agent' | 'agency' = 'agent';

  properties: Property[] = [];

  search = '';

  total = 0;

  options = {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [false],
    target: null,
    search: null,
  };

  filters = {
    annualExpiryDate: null,
    complianceStatus: null,
    lastAttendedDate: null,
  };

  headers = [
    { text: 'Suburb', value: 'suburb', sortable: true, groupable: true },
    { text: 'Street Address', value: 'streetAddress', sortable: true },

    // { text: 'State', value: 'data.state' },
    // { text: 'Postcode', value: 'data.postcode' },
    { text: 'Last Attended', value: 'lastAttended', sortable: true },
    {
      text: 'Annual Service Expiry',
      value: 'annualServiceExpiry',
      sortable: true,
    },
    { text: 'Compliant', value: 'compliant', sortable: false },
    { text: 'Details', value: 'actions', sortable: false },
  ];

  _timerId: number | undefined;
  get target(): string {
    return getTarget();
  }

  set target(target: string) {
    setTarget(target);
  }

  get activeFilters(): number {
    let count = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [key, value] of Object.entries(this.filters)) {
      if (value) {
        count++;
      }
    }
    return count;
  }

  viewDetails(item: Property): void {
    this.viewingDetails = true;
    this.property = item;
  }

  resetProperty(): void {
    this.viewingDetails = false;
    this.property = null;
  }

  mounted(): void {
    if (this.user.data.settings?.properties) {
      this.options = {
        ...this.options,
        ...this.user.data.settings?.properties,
      };
    }

    if (this.$route.query.status) {
      const status = this.$route.query.status;
      this.filters.complianceStatus = status as any;
    }

    this.fetchAgents();
    if (this.user.canViewAllProperties) {
      this.target = 'agency';
    }
  }

  get user(): User {
    return this.$storage.auth.getters.user();
  }

  async fetchAgents(): Promise<void> {
    const user = this.user;
    const agents = await this.$services.api.get(
      `/agencies/${user.data.agency.id}/agents`
    );
    this.agents = agents
      .map((data: UserData) => new User(data))
      .filter((data: User) => data.isPropertyManager == true);
  }

  async updateProperties(): Promise<void> {
    this.loading = true;
    let user;
    if (this.target == 'agency' && this.agent) {
      user = this.agent;
      const { data, total } = await user.getProperties(
        {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          search: this.search,
          target: 'agent',
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
        },
        this.filters
      );
      this.properties = data;
      this.total = total;
    } else {
      user = this.user;
      const { data, total } = await user.getProperties(
        {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          search: this.search,
          target: this.target,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
        },
        this.filters
      );
      this.properties = data;
      this.total = total;
    }
    this.loading = false;
  }

  @Watch('options', { immediate: true, deep: true })
  onOptionsChanged(): void {
    this.updateProperties();
  }

  @Watch('filters', { deep: true })
  onFiltersChanged(): void {
    this.updateProperties();
  }

  @Watch('agent', { deep: true })
  onAgentChanged(): void {
    this.options.page = 1;
    this.updateProperties();
  }

  @Watch('search')
  onSearch(): void {
    this.options.page = 1;
    this.fetchPropertiesDebounced();
  }

  @Watch('target')
  onTargetChange(newVal: 'agency' | 'agent'): void {
    if (newVal == 'agent') {
      this.agent = null;
    }
    this.options.page = 1;
    this.updateProperties();
  }

  fetchPropertiesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = setTimeout(() => {
      this.updateProperties();
    }, 500);
  }
}
