










import BaseRouter from '@/components/BaseRouter.vue';
import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  name: 'App',
  components: {
    BaseRouter,
  },
})
export default class App extends Vue {
  get currentLayout(): string {
    if (this.$route.meta?.layout) {
      return this.$route.meta.layout;
    } else {
      const matched = this.$route.matched.find(
        (route) => route.name == this.$route.name
      );
      if (matched) {
        return matched.parent?.meta.layout;
      }
    }

    // TODO: account for 'default' layout if layout isn't set
    return this.$route.meta?.layout;
  }
}
