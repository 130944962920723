import { render, staticRenderFns } from "./HeaderQuickNav.vue?vue&type=template&id=64ce722e&scoped=true&"
import script from "./HeaderQuickNav.vue?vue&type=script&lang=ts&"
export * from "./HeaderQuickNav.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderQuickNav.vue?vue&type=style&index=0&id=64ce722e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ce722e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VCard,VIcon,VMenu})
