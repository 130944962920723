import { reactive } from '@/plugins/composition';

const initialSettings = {
  target: 'agent',
};

export const settings = reactive({ ...initialSettings });

export const setTarget = (string: string): void => {
  settings.target = string;
};

export const getTarget = (): string => {
  return settings.target;
};
