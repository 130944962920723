import { RouteConfig } from '@/types/router.types';

import { AgentLayoutDefault } from '../../../layouts';

const routes: Array<RouteConfig> = [
  {
    path: '/agent/dashboard',
    name: 'agent.dashboard',
    component: () =>
      import(/* webpackChunkName: "provider-dashboard" */ '../views').then(
        (module) => {
          const { DashboardPage } = module;

          return DashboardPage;
        }
      ),
    meta: {
      layout: AgentLayoutDefault,
      role: [
        'property-manager',
        'principal',
        'admin',
        'accounts',
        'agent',
        'sales-agent',
      ],
    },
  },
  {
    path: '/agent/properties',
    name: 'agent.properties',
    component: () =>
      import(/* webpackChunkName: "provider-dashboard" */ '../views').then(
        (module) => {
          const { PropertiesPage } = module;

          return PropertiesPage;
        }
      ),
    meta: {
      layout: AgentLayoutDefault,
      role: [
        'property-manager',
        'principal',
        'admin',
        'accounts',
        'agent',
        'sales-agent',
      ],
    },
  },
  {
    path: '/agent/jobs',
    name: 'agent.bookings',
    component: () =>
      import(/* webpackChunkName: "provider-dashboard" */ '../views').then(
        (module) => {
          const { BookingsPage } = module;

          return BookingsPage;
        }
      ),
    meta: {
      layout: AgentLayoutDefault,
      role: [
        'property-manager',
        'principal',
        'admin',
        'accounts',
        'agent',
        'sales-agent',
      ],
    },
  },
  {
    path: '/change-password',
    name: 'agent.change.password',
    component: () => import('../views/ChangePassword.vue'),
    meta: {
      layout: AgentLayoutDefault,
      role: [
        'property-manager',
        'principal',
        'admin',
        'accounts',
        'agent',
        'sales-agent',
      ],
    },
  },
];

export { routes };
