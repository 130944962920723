import App from './App.vue';
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import router from './router';
import { BaseModel } from './models';
import { makeStore } from './store';
import { registerServices, registerStorage } from './plugins';
import { vuetify } from './plugins/';

Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

/**
 * Import style overrides.
 * Variables are picked up by vuetify-loader automatically
 */
import './styles/overrides.scss';

// Filters
import './filters/formatDate';

export const store = makeStore();

export const storage = registerStorage(store);
const services = registerServices(store);

BaseModel.setStorage(storage);
BaseModel.setServices(services);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
