


























































































































import Component from 'vue-class-component';
import FilterBookingReason from '@/domains/agent/modules/dashboard/components/FilterBookingReason.vue';
import FilterBookingStatus from '@/domains/agent/modules/dashboard/components/FilterBookingStatus.vue';
import FilterExpiry from '@/domains/agent/modules/dashboard/components/FilterExpiry.vue';
import Vue from 'vue';
import { Agency, User, UserData } from '@/models';
import { Booking } from '@/models/Booking';
import {
  BookingDetails,
  PropertyDetails,
} from '@/domains/agent/modules/dashboard/components';
import { Watch } from 'vue-property-decorator';

type FiltersType = {
  reasons: string[] | null;
  status: string | null;
  bookedDate: string | null;
};

@Component({
  components: {
    PropertyDetails,
    BookingDetails,
    FilterExpiry,
    FilterBookingReason,
    FilterBookingStatus,
  },
})
export default class BookingsPage extends Vue {
  agents: User[] | null = [];
  agent: User | null = null;
  loading = false;
  viewingDetails = false;
  booking: Booking | null = null;
  showFilters = true;
  agencies: Agency[] = [];
  agency: Agency | null = null;
  optionsSet = false;

  target: 'agent' | 'agency' = 'agent';

  bookings: Booking[] = [];

  search = '';

  total = 0;

  options = {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [false],
    target: null,
    search: null,
  };

  filters: FiltersType = {
    reasons: null,
    status: null,
    bookedDate: null,
  };

  headers = [
    { text: 'Suburb', value: 'suburb', sortable: true, groupable: true },
    { text: 'Street Address', value: 'address', sortable: true },
    { text: 'Job #', value: 'jobId', sortable: true },
    { text: 'Reason for Inspection', value: 'reason', sortable: false },
    { text: 'Booked Date', value: 'date', sortable: true },
    { text: 'Job Status', value: 'status', sortable: true },
    { text: 'Details', value: 'actions', sortable: false },
  ];

  _timerId: number | undefined;

  get activeFilters(): number {
    let count = 0;
    for (const [, value] of Object.entries(this.filters)) {
      if (
        (Array.isArray(value) && value.length > 0) ||
        typeof value == 'string'
      ) {
        count++;
      }
    }
    return count;
  }

  viewDetails(item: Booking): void {
    this.viewingDetails = true;
    this.booking = item;
  }

  resetBooking(): void {
    this.viewingDetails = false;
    this.booking = null;
  }

  async mounted(): Promise<void> {
    if (this.user.data.settings?.bookings) {
      this.options = {
        ...this.options,
        ...this.user.data.settings?.bookings,
      };
    }

    await this.fetchAgencies();

    if (this.user.settings?.agency) {
      this.agency =
        this.agencies.find(
          (agency) => agency.id == this.user.settings?.agency
        ) ?? null;
    }

    await this.fetchAgents();

    if (this.user.settings?.agent) {
      this.agent =
        this.agents?.find(
          (agent) => agent.data.id == this.user.settings?.agent
        ) ?? null;
    }

    this.optionsSet = true;
    this.updateBookings();
  }

  get user(): User {
    return this.$storage.auth.getters.user();
  }

  async fetchAgents(): Promise<void> {
    let agents;
    if (this.agency) {
      agents = await this.$services.api.get(
        `/agencies/${this.agency.data.id}/agents`
      );
    } else {
      agents = await this.$services.api.get('/agents');
    }

    this.agents = agents
      .map((data: UserData) => new User(data))
      .filter((data: User) => data.isPropertyManager == true);
  }

  async updateBookings(): Promise<void> {
    this.loading = true;

    const options = {
      page: this.options.page,
      itemsPerPage: this.options.itemsPerPage,
      search: this.search,
      target: '',
      sortBy: this.options.sortBy,
      sortDesc: this.options.sortDesc,
    };

    if (!this.agency && !this.agent) {
      await this.fetchBookings(new User(), options);
    } else if (this.agency && !this.agent) {
      options.target = 'agency';
      const user = new User();
      user.data = {
        agency: {
          id: this.agency.id,
          name: this.agency.data.name,
        },
      } as UserData;
      await this.fetchBookings(user, options);
    } else if (this.agent) {
      options.target = 'agent';
      const user = this.agent;
      await this.fetchBookings(user, options);
    }

    this.loading = false;
  }

  async fetchBookings(user: User, options: any): Promise<void> {
    const { data, total } = await user.getBookings(options, this.filters);
    this.bookings = data;
    this.total = total;
  }

  async fetchAgencies(): Promise<void> {
    this.agencies = await Agency.get();
  }

  @Watch('options', { deep: true })
  onOptionsChanged(): void {
    if (this.optionsSet) {
      this.updateBookings();
    }
  }

  @Watch('filters', { deep: true })
  onFiltersChanged(): void {
    this.updateBookings();
  }

  @Watch('agent', { deep: true })
  onAgentChanged(): void {
    this.options.page = 1;
    this.updateBookings();
    this.user.saveSelectedAgent(this.agent);
  }

  @Watch('agency', { deep: true })
  onAgencyChanged(): void {
    this.updateBookings();
    this.fetchAgents();
    this.user.saveSelectedAgency(this.agency);
    this.agent = null;
  }

  @Watch('search')
  onSearch(): void {
    this.options.page = 1;
    this.fetchbookingsDebounced();
  }

  @Watch('target')
  onTargetChange(newVal: string): void {
    if (newVal == 'agent') {
      this.agent = null;
    }
    this.options.page = 1;
    this.updateBookings();
  }

  fetchbookingsDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = setTimeout(() => {
      this.updateBookings();
    }, 500);
  }
}
