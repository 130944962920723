import parseISO from 'date-fns/parseISO';
import { BaseModel } from './BaseModel';
import { PropertyData } from './Property';

export type BookingData = {
  readonly id: number;
  readonly bookedDate?: string;
  readonly eta?: string;
  readonly startTime?: string;
  readonly endTime?: string;
  readonly status?: string;
  readonly reasons?: string[];
  readonly property: PropertyData;
  readonly jobId: string;
  readonly workOrder?: string;
};

export class Booking extends BaseModel<BookingData> {
  get slug(): string {
    return 'bookings';
  }

  get jobId(): string {
    return this.data.jobId;
  }

  get date(): Date | null {
    if (this.data.bookedDate) {
      return parseISO(this.data.bookedDate);
    }

    return null;
  }

  reasonColour(reason: string): {
    colour: string;
    dark: boolean;
  } {
    const colour = {
      colour: 'red lighten-1',
      dark: true,
    };
    if (reason.toLowerCase().includes('upgrade')) {
      colour.colour = 'red lighten-2';
      colour.dark = true;
    }

    if (reason.toLowerCase().includes('pool')) {
      colour.colour = 'blue';
      colour.dark = true;
    }

    if (reason.toLowerCase().includes('vacant')) {
      colour.colour = 'orange';
      colour.dark = false;
    }

    return colour;
  }

  async downloadFile(type: 'work-order'): Promise<void> {
    await BaseModel.$api
      .get(
        `/agencies/${this.data.property.agency.id}/bookings/${this.data.id}/${type}`,
        { responseType: 'blob' }
      )
      .then((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        window.open(link.href);
        // link.download = `${type.toUpperCase()} - ${
        //   this.data.jobId
        //     ? this.data.jobId.replace('/', '').replace('\\', '')
        //     : 'Be Legal'
        // }`;
        // link.click();
      });
  }

  getFileUrl(type: 'work-order'): string {
    return `/agencies/${this.data.property.agency.id}/bookings/${this.data.id}/${type}`;
  }
}
