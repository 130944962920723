












































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { User } from '@/models/User';

@Component({
  name: 'LoginForm',
})
export default class LoginForm extends Vue {
  loading = false;

  valid = false;

  showPassword = false;

  message = '';

  credentials = {
    email: '',
    password: '',
  };

  rules = {
    email: [(v: string): string | boolean => !!v || 'Email is required'],
    password: [(v: string): string | boolean => !!v || 'Password is required'],
  };

  user: User | null = null;

  get loggedIn(): boolean {
    return this.$storage.auth.getters.isAuthed();
  }

  async login(): Promise<void> {
    this.loading = true;
    try {
      const user = await User.login(this.credentials);
      this.user = user;
      if (user) {
        this.$storage.auth.actions.login(user);
        this.$emit('success');
      }
    } catch (e) {
      this.message = 'Incorrect credentials.';
    }

    this.loading = false;
  }

  async logout(): Promise<void> {
    await User.logout();
  }

  async mounted(): Promise<void> {
    if (this.loggedIn) {
      this.user = await User.getCurrentUser();
    }
  }
}
