






import ResetPasswordForm from '../components/ResetPasswordForm.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'ResetPasswordPage',
  components: {
    ResetPasswordForm,
  },
})
export default class ResetPasswordPage extends Vue {
  @Prop(String) readonly token!: string;
}
