








import Component from 'vue-class-component';
import NavMenu from './NavMenu.vue';
import Vue from 'vue';

@Component({
  components: {
    NavMenu,
  },
})
export default class MainMenu extends Vue {
  menu = [
    {
      items: [
        {
          icon: 'mdi-view-dashboard-outline',
          text: 'Dashboard',
          route: { name: 'agent.dashboard' },
        },
        {
          icon: 'mdi-home',
          text: 'Active Properties',
          route: { name: 'agent.properties' },
        },
        {
          icon: 'mdi-calendar',
          text: 'Active Jobs',
          route: { name: 'agent.bookings' },
        },
      ],
    },
    // {
    //   text: 'Business',
    //   items: [
    //     {
    //       icon: 'mdi-account-multiple',
    //       text: 'Users',
    //       route: { name: 'users.all' },
    //     },
    //   ],
    // },
  ];
}
