




import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { BaseModel } from '@/models';
import { defineComponent, onMounted, ref } from '@/plugins/composition';

export default defineComponent({
  name: 'PdfViewer',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    VuePdfEmbed,
  },
  setup(props, { emit }) {
    const data = ref<ArrayBuffer | null>(null);

    onMounted(async () => {
      data.value = await BaseModel.$api
        .get(props.url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          return blob.arrayBuffer();
        });
    });
    return {
      data,
    };
  },
});
