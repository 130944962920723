




















































import Component from 'vue-class-component';
import PdfViewer from '@/components/PdfViewer.vue';
import Vue from 'vue';
import { Booking } from '@/models/Booking';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    PdfViewer,
  },
})
export default class PropertyDetails extends Vue {
  quoteLoading = false;
  complianceLoading = false;
  @Prop(Booking) readonly booking!: Booking;

  async downloadWorkOrder(): Promise<void> {
    this.quoteLoading = true;
    await this.booking.downloadFile('work-order');
    this.quoteLoading = false;
  }
}
