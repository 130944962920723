import { IAuthState } from '../auth.types';
import { IMutations } from './mutations.types';
import { User } from '@/models/User';

export const mutations: IMutations = {
  login(state: IAuthState, user: User) {
    state.user = user.data;
  },
  setAuthSuccess(state: IAuthState) {
    state.authenticated = true;
  },
  resetAuth(state) {
    state.user = null;
    state.authenticated = false;
  },
};
