import { RouteConfig } from '@/types/router.types';
import { store } from '@/main';

import { SharedLayoutMain } from '../../../layouts';

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views').then((module) => {
        const { LoginPage } = module;

        return LoginPage;
      }),
    meta: {
      layout: SharedLayoutMain,
      requiresAuth: false,
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    beforeEnter: (to, from, next) => {
      const user = store.$storage.auth.getters.user();
      if (user && user.isAgent) {
        next({ name: 'agent.properties' });
      } else {
        next();
      }
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views').then((module) => {
        const { ResetPasswordPage } = module;

        return ResetPasswordPage;
      }),
    meta: {
      layout: SharedLayoutMain,
      requiresAuth: false,
    },
    props: (route) => ({ token: route.query.token }),
  },
];

export { routes };
