import Vue from 'vue';
import { IServiceProvider, serviceProvider } from '../services';
import { IStore } from '@/store/store.types';

// TODO - store type
export const registerServices = (store: IStore): IServiceProvider => {
  const services = serviceProvider();

  store.$services = services;
  Vue.mixin({
    beforeCreate() {
      this.$services = store.$services;
    },
  });

  return services;
};
