


























































/* eslint-disable @typescript-eslint/no-explicit-any */
import ComplianceChart from '../components/ComplianceChart.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import { User, UserData } from '@/models';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {
    ComplianceChart,
  },
})
export default class DashboardPage extends Vue {
  data: any[] = [];
  agents: User[] | null = [];
  loading = false;
  target: 'agent' | 'agency' = 'agent';
  agent: User | null = null;

  headers = [
    { text: 'Status', value: 'status' },
    { text: 'Count', value: 'count' },
  ];

  get user(): User {
    return this.$storage.auth.getters.user();
  }

  async filter(item: any): Promise<void> {
    this.$router.push({
      name: 'agent.properties',
      query: { status: item.status },
    });
  }

  async mounted(): Promise<void> {
    if (this.user.canViewAllProperties) {
      this.target = 'agency';
    }
    this.data = await this.user.propertyMetrics().catch(() => []);
    this.fetchAgents();
  }

  async getPropertyMetrics(): Promise<void> {
    this.loading = true;
    let user;
    if (this.target == 'agency' && this.agent) {
      user = this.agent;
      this.data = await user.propertyMetrics();
    } else {
      this.data = await this.user.propertyMetrics({ target: this.target });
    }
    this.loading = false;
  }

  async fetchAgents(): Promise<void> {
    const user = this.user;
    const agents = await this.$services.api.get(
      `/agencies/${user.data.agency.id}/agents`
    );
    this.agents = agents
      .map((data: UserData) => new User(data))
      .filter((data: User) => data.isPropertyManager == true);
  }

  @Watch('target')
  onTargetChange(newVal: string): void {
    if (newVal == 'agent') {
      this.agent = null;
    }

    this.getPropertyMetrics();
  }

  @Watch('agent', { deep: true })
  onAgentChanged(): void {
    this.getPropertyMetrics();
  }
}
