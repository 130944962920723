import { IAuthState } from '../auth.types';
import { IRootState } from '@/store/store.types';
import { Store } from 'vuex/types/index';
import { User } from '@/models/User';

export class AuthStorage {
  constructor(
    public store: Store<IRootState>,
    public actions = {
      login(payload: User) {
        return store.dispatch('auth/login', payload);
      },
      async checkAuth(): Promise<void> {
        return await store.dispatch('auth/checkAuth');
      },
      async logout(): Promise<void> {
        return await store.dispatch('auth/logout');
      },
    },
    public mutations = {
      login(payload: User) {
        store.commit('auth/login', payload);
      },
      setAuthSuccess() {
        store.commit('auth/setAuthSuccess');
      },
      resetAuth() {
        store.commit('auth/resetAuth');
      },
    },
    public getters = {
      isAuthed(): boolean {
        return store.getters['auth/isAuthed'];
      },
      getAuth(): IAuthState {
        return store.getters['auth/getAuth'];
      },
      user(): User {
        return store.getters['auth/user'];
      },
    }
  ) {}
}
