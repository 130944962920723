import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { AuthStore } from './auth';
import { IRootState } from './store.types';

Vue.use(Vuex);

export const makeStore = (): Store<IRootState> =>
  new Vuex.Store<IRootState>({
    modules: {
      auth: AuthStore,
    },
  });
