import Vue from 'vue';
import { IStorageProvider, storageProvider } from '../store';
import { IStore } from '@/store/store.types';

export const registerStorage = (store: IStore): IStorageProvider => {
  const storage = storageProvider(store);

  store.$storage = storage;
  Vue.mixin({
    beforeCreate() {
      this.$storage = store.$storage;
    },
  });

  return storage;
};
