








































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { User } from '@/models';

@Component({
  name: 'ForgotPasswordForm',
})
export default class ForgotPasswordForm extends Vue {
  loading = false;
  reset = false;

  message: string | null = null;

  valid = false;

  credentials = {
    email: '',
  };

  rules = {
    email: [(v: string): string | boolean => !!v || 'Email is required'],
  };

  async submit(): Promise<void> {
    this.loading = true;
    const response = await User.forgotPassword(this.credentials.email);
    if (response) {
      this.message = response.message;
      this.reset = true;
    } else {
      this.loading = false;
    }
    this.loading = false;
  }
}
