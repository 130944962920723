



















import Component from 'vue-class-component';
import Vue from 'vue';
import { VModel } from 'vue-property-decorator';

@Component
export default class FilterBookingReason extends Vue {
  items: string[] = [];

  @VModel({ type: Array }) reasons!: string[];

  async mounted(): Promise<void> {
    this.items = await this.$services.api.get('/booking-reasons');
  }
}
