import { Context, IActions } from './actions.types';
import { IAuthState } from '../auth.types';
import { User } from '@/models/User';

const setLocalAuth = (state: IAuthState): void => {
  window.localStorage.setItem('auth', JSON.stringify(state));
};

const getLocalAuth = (): IAuthState | null => {
  const storedAuth = window.localStorage.getItem('auth');
  return storedAuth ? JSON.parse(storedAuth) : null;
};

const clearLocalAuth = (): void => {
  window.localStorage.clear();
};

export const actions: IActions = {
  async login(context: Context, payload: User): Promise<boolean> {
    this.$storage.auth.mutations.login(payload);
    this.$storage.auth.mutations.setAuthSuccess();
    setLocalAuth(this.$storage.auth.getters.getAuth());
    return true;
  },
  async checkAuth(): Promise<User | null> {
    let user: User | null = null;
    const storedUser = getLocalAuth();
    if (storedUser) {
      try {
        user = await User.getCurrentUser();
        this.$storage.auth.mutations.login(user);
        this.$storage.auth.mutations.setAuthSuccess();
      } catch (e) {
        clearLocalAuth();
      }
    }

    return user;
  },
  async logout(): Promise<void> {
    clearLocalAuth();
    this.$storage.auth.mutations.resetAuth();
  },
};
