











































































































































import Component from 'vue-class-component';
import FilterCompliant from '@/domains/agent/modules/dashboard/components/FilterCompliant.vue';
import FilterExpiry from '@/domains/agent/modules/dashboard/components/FilterExpiry.vue';
import Vue from 'vue';
import { Agency, User, UserData } from '@/models';
import { Property } from '@/models/Property';
import { PropertyDetails } from '@/domains/agent/modules/dashboard/components';
import { Watch } from 'vue-property-decorator';
import { getTarget, setTarget } from '@/store/settings';

@Component({
  components: {
    PropertyDetails,
    FilterExpiry,
    FilterCompliant,
  },
})
export default class PropertiesPage extends Vue {
  agents: User[] | null = [];
  agent: User | null = null;
  viewingDetails = false;
  property: Property | null = null;
  loading = false;
  showFilters = true;
  agencies: Agency[] = [];
  agency: Agency | null = null;
  optionsSet = false;
  filtersSet = false;
  properties: Property[] = [];
  search = '';
  total = 0;

  options = {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [false],
    target: null,
    search: null,
  };

  filters = {
    annualExpiryDate: null,
    complianceStatus: null,
    lastAttendedDate: null,
  };

  headers = [
    { text: 'Suburb', value: 'suburb', sortable: true, groupable: true },
    { text: 'Street Address', value: 'streetAddress', sortable: true },
    { text: 'Last Attended', value: 'lastAttended', sortable: true },
    {
      text: 'Annual Service Expiry',
      value: 'annualServiceExpiry',
      sortable: true,
    },
    { text: 'Compliant', value: 'compliant', sortable: false },
    { text: 'Details', value: 'actions', sortable: false },
  ];

  _timerId: number | undefined;

  get target(): string {
    return getTarget();
  }

  set target(target: string) {
    setTarget(target);
  }

  get activeFilters(): number {
    let count = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [key, value] of Object.entries(this.filters)) {
      if (value) {
        count++;
      }
    }
    return count;
  }

  get user(): User {
    return this.$storage.auth.getters.user();
  }

  viewDetails(item: Property): void {
    this.viewingDetails = true;
    this.property = item;
  }

  resetProperty(): void {
    this.viewingDetails = false;
    this.property = null;
  }

  async mounted(): Promise<void> {
    if (this.user.data.settings?.properties) {
      this.options = {
        ...this.options,
        ...this.user.data.settings?.properties,
      };
    }

    if (this.$route.query.status) {
      const status = this.$route.query.status;
      this.filters.complianceStatus = status as any;
    }

    await this.fetchAgencies();

    if (this.user.settings?.agency) {
      this.agency =
        this.agencies.find(
          (agency) => agency.id == this.user.settings?.agency
        ) ?? null;
    }

    await this.fetchAgents();

    if (this.user.settings?.agent) {
      this.agent =
        this.agents?.find(
          (agent) => agent.data.id == this.user.settings?.agent
        ) ?? null;
    }

    this.optionsSet = true;
    this.filtersSet = true;
    this.updateProperties();
  }

  async fetchAgents(): Promise<void> {
    let agents;
    if (this.agency) {
      agents = await this.$services.api.get(
        `/agencies/${this.agency.data.id}/agents`
      );
    } else {
      agents = await this.$services.api.get('/agents');
    }

    this.agents = agents
      .map((data: UserData) => new User(data))
      .filter((data: User) => data.isPropertyManager == true);
  }

  async updateProperties(): Promise<void> {
    this.loading = true;
    const options = {
      page: this.options.page,
      itemsPerPage: this.options.itemsPerPage,
      search: this.search,
      target: '',
      sortBy: this.options.sortBy,
      sortDesc: this.options.sortDesc,
    };

    if (!this.agency && !this.agent) {
      await this.fetchProperties(new User(), options);
    } else if (this.agency && !this.agent) {
      options.target = 'agency';
      const user = new User();
      user.data = {
        agency: {
          id: this.agency.id,
          name: this.agency.data.name,
        },
      } as UserData;
      await this.fetchProperties(user, options);
    } else if (this.agent) {
      options.target = 'agent';
      const user = this.agent;
      await this.fetchProperties(user, options);
    }

    this.loading = false;
  }

  async fetchProperties(user: User, options: any): Promise<void> {
    const { data, total } = await user.getProperties(options, this.filters);
    this.properties = data;
    this.total = total;
  }

  async fetchAgencies(): Promise<void> {
    this.agencies = await Agency.get();
  }

  @Watch('options', { deep: true })
  onOptionsChanged(): void {
    if (this.optionsSet) {
      this.updateProperties();
    }
  }

  @Watch('filters', { deep: true })
  onFiltersChanged(): void {
    if (this.filtersSet) {
      this.updateProperties();
    }
  }

  @Watch('agent', { deep: true })
  onAgentChanged(): void {
    this.options.page = 1;
    if (this.filtersSet) {
      this.updateProperties();
    }
    this.user.saveSelectedAgent(this.agent);
  }

  @Watch('agency', { deep: true })
  onAgencyChanged(): void {
    if (this.filtersSet) {
      this.updateProperties();
    }
    this.fetchAgents();
    this.user.saveSelectedAgency(this.agency);
    this.agent = null;
  }

  @Watch('search')
  onSearch(): void {
    this.options.page = 1;
    this.fetchPropertiesDebounced();
  }

  @Watch('target')
  onTargetChange(newVal: 'agency' | 'agent'): void {
    if (newVal == 'agent') {
      this.agent = null;
    }
    this.options.page = 1;
    this.updateProperties();
  }

  fetchPropertiesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = setTimeout(() => {
      this.updateProperties();
    }, 500);
  }
}
