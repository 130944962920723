








import Component from 'vue-class-component';
import NavMenu from './NavMenu.vue';
import Vue from 'vue';

@Component({
  components: {
    NavMenu,
  },
})
export default class MainMenu extends Vue {
  menu = [
    {
      items: [
        {
          icon: 'mdi-view-dashboard-outline',
          text: 'Dashboard',
          route: { name: 'admin.dashboard' },
        },
        {
          icon: 'mdi-home',
          text: 'Active Properties',
          route: { name: 'admin.properties' },
        },
        {
          icon: 'mdi-calendar',
          text: 'Active Jobs',
          route: { name: 'admin.bookings' },
        },
      ],
    },
  ];
}
