































































































































import Component from 'vue-class-component';
import PdfViewer from '@/components/PdfViewer.vue';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Property } from '@/models/Property';
import { differenceInMonths } from 'date-fns';

@Component({
  components: {
    PdfViewer,
  },
})
export default class PropertyDetails extends Vue {
  quoteLoading = false;
  complianceLoading = false;
  poolLoading = false;
  tab = 0;
  @Prop(Property) readonly property!: Property;

  get sinceLastAttended(): number {
    return this.property.lastAttended
      ? differenceInMonths(new Date(), this.property.lastAttended)
      : 0;
  }

  get untilServiceExpiry(): number {
    return this.property.annualServiceExpiry
      ? differenceInMonths(this.property.annualServiceExpiry, new Date())
      : 0;
  }

  async downloadQuote(): Promise<void> {
    this.quoteLoading = true;
    await this.property.downloadFile('quote');
    this.quoteLoading = false;
  }

  async downloadCompliance(): Promise<void> {
    this.complianceLoading = true;
    await this.property.downloadFile('compliance');
    this.complianceLoading = false;
  }

  async downloadPoolCertificate(): Promise<void> {
    this.poolLoading = true;
    await this.property.downloadFile('pool');
    this.poolLoading = false;
  }

  dateDiff(date: string): number {
    return differenceInMonths(new Date(date), new Date());
  }
}
