import { IAuthState } from '../auth.types';
import { IGetters } from './getters.types';
import { User } from '@/models';

export const getters: IGetters = {
  isAuthed(state: IAuthState): boolean {
    return state.authenticated;
  },
  getAuth(state: IAuthState): IAuthState {
    return state;
  },
  user(state: IAuthState): User | null {
    const user = state.user ? new User(state.user) : null;
    return user;
  },
};
