import { Agency } from '.';
import { AgencyData } from './Agency';
import { AuthService, LoginCredentials } from '@/services';
import { AuthStorage } from '@/store/auth';
import { BaseModel } from './BaseModel';
import { Booking, BookingData } from './Booking';
import { Property, PropertyData } from './Property';

export type UserData = {
  readonly id: number;
  readonly email: string;
  readonly firstName: string;
  readonly lastName?: string;
  readonly role:
    | 'property-manager'
    | 'principal'
    | 'admin'
    | 'accounts'
    | 'agent'
    | 'super-admin'
    | 'sales-agent';
  readonly agency: AgencyData;
  settings: any;
  agentId: string;
};

type Filters = {
  annualExpiryDate: null | string;
  lastAttendedDate: null | string;
  complianceStatus: null | string;
} | null;

export class User extends BaseModel<UserData> {
  static get $service(): AuthService {
    return BaseModel.serviceProvider.auth;
  }

  static get $storage(): AuthStorage {
    return BaseModel.storageProvider.auth;
  }

  static async login(credentials: LoginCredentials): Promise<User> {
    const response = await User.$service.login(credentials);
    return response;
  }

  static async logout(): Promise<void> {
    const response = await User.$service.logout();
    User.$storage.actions.logout();
    return response;
  }

  static async forgotPassword(email: string): Promise<{ message: string }> {
    const response = BaseModel.$api.post('/forgot-password', { email });
    return response;
  }

  static async resetPassword(
    credentials: LoginCredentials & {
      password_confirmation: string;
      token: string;
    }
  ): Promise<{ message: string }> {
    const response = BaseModel.$api.post('/reset-password', credentials);
    return response;
  }

  static async getCurrentUser(): Promise<User> {
    const response = await BaseModel.$api.get<UserData>('/user');
    return new User(response);
  }

  static async setCurrentProvider(uuid: string): Promise<User> {
    const response = await User.$api.post<UserData>('/user/provider/' + uuid);
    return new User(response);
  }

  static authedUser(): User {
    return User.$storage.getters.user();
  }

  get settings(): any {
    return this.data.settings;
  }

  get name(): string {
    return `${this.data.firstName} ${this.data.lastName ?? ''}`;
  }

  get initials(): string {
    const first = this.data.firstName.charAt(0);
    const last = this.data.lastName ? this.data.lastName.charAt(0) : '';

    return first + last;
  }

  get isAgent(): boolean {
    return this.data.role == 'agent';
  }

  get isPropertyManager(): boolean {
    return this.data.role == 'property-manager' || this.isSalesAgent;
  }

  get isPrincipal(): boolean {
    return this.data.role == 'principal';
  }

  get isAccounts(): boolean {
    return this.data.role == 'accounts';
  }

  get isAdmin(): boolean {
    return this.data.role == 'admin';
  }

  get isSuperAdmin(): boolean {
    return this.data.role == 'super-admin';
  }

  get isSalesAgent(): boolean {
    return this.data.role == 'sales-agent';
  }

  get canViewAllProperties(): boolean {
    return !this.isAgent && !this.isPropertyManager;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async propertyMetrics(options = { target: 'agent' }): Promise<any> {
    if (options.target == 'agent') {
      // Target is agent
      return BaseModel.$api.get(
        `/agents/${this.data.agentId}/properties/metrics`
      );
    } else {
      // Target is agency
      return BaseModel.$api.get(
        `/agencies/${this.data.agency.id}/properties/metrics`
      );
    }
  }

  async saveSelectedAgent(agent: User | null): Promise<void> {
    this.saveSettings('agent', agent?.data.id ?? null);
  }

  async saveSelectedAgency(agency: Agency | null): Promise<void> {
    this.saveSettings('agency', agency?.data.id ?? null);
  }

  async updatePassword(oldPassword: string, newPassword: string): Promise<any> {
    await BaseModel.$api.put('/user/password', {
      current_password: oldPassword,
      password: newPassword,
    });

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async saveSettings(type: string, options: any): Promise<void> {
    const user = User.authedUser();

    const settings = {
      ...user.settings,
      [type]: options,
    };

    user.data.settings = await BaseModel.$api.post(
      `/users/${user.data.id}/settings`,
      settings
    );
  }

  async getProperties(
    options = {
      page: 1,
      itemsPerPage: 50,
      search: '',
      target: 'agent',
      sortBy: [''],
      sortDesc: [false],
    },
    filters: Filters = null
  ): Promise<{ data: Property[]; total: number }> {
    this.saveSettings('properties', options);
    let query;
    if (options.target == 'agency') {
      query = `/agencies/${this.data.agency.id}/properties?page=${options.page}&per_page=${options.itemsPerPage}`;
    } else if (options.target == 'agent') {
      query = `/agents/${this.data.agentId}/properties?page=${options.page}&per_page=${options.itemsPerPage}`;
    } else {
      query = `/properties?page=${options.page}&per_page=${options.itemsPerPage}`;
    }

    if (options.sortBy[0]) {
      query = query.concat(
        `&sort=${options.sortDesc[0] ? '-' : ''}${options.sortBy[0]
          .split(/(?=[A-Z])/)
          .join('_')
          .toLowerCase()}`
      );
    }

    if (options.search) {
      query = query.concat(`&filter[street_address]=${options.search}`);
    }

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value) {
          query = query.concat(
            `&filter[${key
              .split(/(?=[A-Z])/)
              .join('_')
              .toLowerCase()}]=${value}`
          );
        }
      }
    }

    let properties;
    try {
      properties = await BaseModel.$api.get(query);
    } catch (e) {
      properties = [];
    }

    if (properties.data) {
      const data = properties.data.map(
        (data: PropertyData) => new Property(data)
      );
      const total = properties.meta.total;
      return { data, total };
    }

    const data = properties.map((data: PropertyData) => new Property(data));
    const total = data.length;
    return { data, total };
  }

  async getBookings(
    options = {
      page: 1,
      itemsPerPage: 50,
      search: '',
      target: 'agent',
      sortBy: [''],
      sortDesc: [false],
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters: any = null
  ): Promise<{ data: Booking[]; total: number }> {
    let query;
    this.saveSettings('bookings', options);
    if (options.target == 'agency') {
      query = `/agencies/${this.data.agency.id}/bookings?page=${options.page}&per_page=${options.itemsPerPage}`;
    } else if (options.target == 'agent') {
      query = `/agents/${this.data.agentId}/bookings?page=${options.page}&per_page=${options.itemsPerPage}`;
    } else {
      query = `/bookings?page=${options.page}&per_page=${options.itemsPerPage}`;
    }

    if (options.sortBy[0]) {
      query = query.concat(
        `&sort=${options.sortDesc[0] ? '-' : ''}${options.sortBy[0]
          .split(/(?=[A-Z])/)
          .join('_')
          .toLowerCase()}`
      );
    }

    if (options.search) {
      query = query.concat(
        `&filter[properties.street_address]=${options.search}`
      );
    }

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value) {
          query = query.concat(
            `&filter[${key
              .split(/(?=[A-Z])/)
              .join('_')
              .toLowerCase()}]=${value}`
          );
        }
      }
    }

    let properties;
    try {
      properties = await BaseModel.$api.get(query);
    } catch (e) {
      properties = [];
    }

    if (properties.data) {
      const data = properties.data.map(
        (data: BookingData) => new Booking(data)
      );
      const total = properties.meta.total;
      return { data, total };
    }

    const data = properties.map((data: BookingData) => new Booking(data));
    const total = data.length;
    return { data, total };
  }
}
