/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from 'axios';
import { axios } from '@/plugins/axios';

export type APIResponse<T> = Promise<T>;

export interface APIRequestConfig extends AxiosRequestConfig {
  scoped?: boolean;
}

export class APIService {
  constructor(
    public defaultBaseUrl: string | undefined = axios.defaults.baseURL,
    public providerUrl: string | null = ''
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  baseUrl(options: APIRequestConfig = { scoped: true }): string {
    const url = this.defaultBaseUrl;

    return url as string;
  }

  config(options?: APIRequestConfig): AxiosRequestConfig {
    return {
      baseURL: this.baseUrl(options),
      responseType: options?.responseType,
    };
  }

  async get<T = any>(url: string, options?: APIRequestConfig): APIResponse<T> {
    const response = await axios.get(url, this.config(options));

    return response.data;
  }

  async post<T = any>(
    url: string,
    data: unknown = null,
    options?: APIRequestConfig
  ): APIResponse<T> {
    try {
      const response = await axios.post(url, data, this.config(options));
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async put<T = any>(
    url: string,
    data: unknown = null,
    options?: APIRequestConfig
  ): APIResponse<T> {
    const response = await axios.put(url, data, this.config(options));
    return response.data;
  }
}
