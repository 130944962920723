// import Vue from 'vue'
import baseAxios from 'axios';
import router from '@/router';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

const config: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
};

const axios: AxiosInstance = baseAxios.create(config);

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status == 401) {
      router.push({ name: 'login' });
    }
    return Promise.reject(error);
  }
);

// Plugin.install = function (Vue, options) {
//   Vue.axios = _axios
//   window.axios = _axios
//   Object.defineProperties(Vue.prototype, {
//     axios: {
//       get() {
//         return _axios
//       },
//     },
//     $axios: {
//       get() {
//         return _axios
//       },
//     },
//   })
// }

// Vue.use(Plugin)

export { axios };
