import { BaseModel } from './BaseModel';

export type AgencyData = {
  id: number;
  readonly name: string;
};

export class Agency extends BaseModel<AgencyData> {
  get slug(): string {
    return 'agencies';
  }

  get id(): number {
    return this.data.id;
  }

  set id(number: number) {
    this.data.id = number;
  }
}
