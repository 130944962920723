









import Component from 'vue-class-component';
import Vue from 'vue';
import { VModel } from 'vue-property-decorator';

@Component
export default class FilterExpiry extends Vue {
  items = [
    {
      text: 'COMPLIANT WITH NEW 2022 QLD LEGISLATION',
      value: 'COMPLIANT WITH NEW 2022 QLD LEGISLATION',
    },
    {
      text: 'COMPLIANT WITH CURRENT QLD LEGISLATION',
      value: 'COMPLIANT WITH CURRENT QLD LEGISLATION',
    },
    {
      text: 'NOT COMPLIANT WITH CURRENT LEGISLATION',
      value: 'NOT COMPLIANT WITH CURRENT LEGISLATION',
    },
    {
      text: 'UPGRADED BY ANOTHER COMPANY',
      value: 'UPGRADED BY ANOTHER COMPANY',
    },
    {
      text: 'STATUS NOT SET',
      value: 'STATUS NOT SET',
    },
  ];

  @VModel({ type: String }) compliant!: string;
}
