import { RouteConfig } from '@/types/router.types';

import { AdminLayoutDefault } from '../../../layouts';

const routes: Array<RouteConfig> = [
  {
    path: '/admin/dashboard',
    name: 'admin.dashboard',
    component: () =>
      import(/* webpackChunkName: "provider-dashboard" */ '../views').then(
        (module) => {
          const { DashboardPage } = module;

          return DashboardPage;
        }
      ),
    meta: {
      layout: AdminLayoutDefault,
      role: ['super-admin'],
    },
  },
  {
    path: '/admin/properties',
    name: 'admin.properties',
    component: () =>
      import(/* webpackChunkName: "provider-dashboard" */ '../views').then(
        (module) => {
          const { PropertiesPage } = module;

          return PropertiesPage;
        }
      ),
    meta: {
      layout: AdminLayoutDefault,
      role: ['super-admin'],
    },
  },
  {
    path: '/admin/jobs',
    name: 'admin.bookings',
    component: () =>
      import(/* webpackChunkName: "provider-dashboard" */ '../views').then(
        (module) => {
          const { BookingsPage } = module;

          return BookingsPage;
        }
      ),
    meta: {
      layout: AdminLayoutDefault,
      role: ['super-admin'],
    },
  },
];

export { routes };
