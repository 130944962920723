


























/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Property } from '@/models/Property';
import { User } from '@/models';

@Component({})
export default class ComplianceChart extends Vue {
  @Prop() readonly data!: any[];
  @Prop() readonly loading!: boolean;

  get labels(): any {
    return this.data.map((value) => {
      return value.status.length > 0 ? value.status : 'STATUS NOT SET';
    });
  }

  get series(): any {
    return this.data.map((value) => value.count);
  }

  get user(): User {
    return this.$storage.auth.getters.user();
  }

  get chartOptions(): any {
    return {
      chart: {
        type: 'pie',
        animations: {
          speed: 400,
        },
        background: 'transparent',
      },
      stroke: {
        show: true,
        // colors: [this.$vuetify.theme.isDark ? '#333' : '#fff'],
        width: 1,
        dashArray: 0,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '74%',
          },
        },
      },
      //   theme: {
      //     mode: this.$vuetify.theme.isDark ? 'dark' : 'light',
      //   },
      labels: this.labels,
      dataLabels: {
        enabled: false,
      },
      colors: [
        function (item: any) {
          const label = item.w.config.labels[item.seriesIndex];
          const property = new Property();
          property.data.compliant = label;
          return property.complianceColour.length > 0
            ? property.complianceColour
            : 'blue';
        },
      ],
      fill: {
        colors: [
          function (item: any) {
            const label = item.w.config.labels[item.seriesIndex];
            const property = new Property();
            property.data.compliant = label;
            return property.complianceColour.length > 0
              ? property.complianceColour
              : 'blue';
          },
        ],
      },
      legend: {
        offsetX: 40,
        offsetY: 40,
        fontSize: '15px',
        fontFamily: 'Quicksand',
        fontWeight: 400,

        // position: 'bottom',
        floating: false,
      },
    };
  }
}
