
















































import Component from 'vue-class-component';
import Vue from 'vue';
import { User } from '@/models/User';

@Component
export default class HeaderUserProfile extends Vue {
  get initials(): string {
    return this.$storage.auth.getters.user()?.initials;
  }

  async logout(): Promise<void> {
    await User.logout();
    this.$router.push({ name: 'login' });
  }

  async resetSettings(): Promise<void> {
    await this.$services.api.post(
      `/users/${this.$storage.auth.getters.user().data.id}/settings/reset`
    );
  }
}
