import { Auth } from './auth';
import { RouteConfig } from 'vue-router';

type Module = 'Auth';

export const modules = {
  Auth,
};

export const getModuleRoutes = (module: Module): Array<RouteConfig> => {
  const importedModule = modules[module];

  return importedModule.routes;
};
