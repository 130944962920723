























import Component from 'vue-class-component';
import Vue from 'vue';
import format from 'date-fns/format/index.js';
import { Prop, VModel } from 'vue-property-decorator';
import { parseISO } from 'date-fns/fp';

@Component
export default class FilterExpiry extends Vue {
  @Prop(String) readonly label!: string;
  menu = false;

  @VModel({ type: String }) date!: string;

  get formattedDate(): null | string {
    if (!this.date) {
      return '';
    }
    return format(parseISO(this.date), 'MMM yyyy');
  }

  set formattedDate(value: null | string) {
    this.date = '';
  }
}
