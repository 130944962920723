







import ForgotPasswordForm from '../components/ForgotPasswordForm.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { LoginForm } from '../components';
import { SharedLayoutMain } from '../../../layouts';
import { User } from '@/models';

@Component({
  name: 'LoginPage',
  components: {
    SharedLayoutMain,
    LoginForm,
    ForgotPasswordForm,
  },
})
export default class LoginPage extends Vue {
  forgot = false;
  login(): void {
    if (this.user.isSuperAdmin) {
      this.$router.push({ name: 'admin.dashboard' });
    } else {
      this.$router.push({ name: 'agent.dashboard' });
    }
  }

  get user(): User {
    return this.$storage.auth.getters.user();
  }
}
