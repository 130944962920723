import colors from 'vuetify/lib/util/colors';
import { BaseModel } from './BaseModel';
import { parseISO } from 'date-fns';

export type PropertyData = {
  readonly id: number;
  readonly lastAttended: string | null;
  readonly annualServiceExpiry: string | null;
  compliant: string | null;
  readonly streetAddress: string;
  readonly suburb: string;
  readonly postcode: string;
  readonly lastQuote: string | null;
  readonly complianceCertificate: string | null;
  readonly agency: {
    id: string;
  };
  pool: {
    lastInspected: string;
    poolExpiryDate: string;
    certificate: string;
    compliance: string;
  };
  hasPool: boolean;
};

export class Property extends BaseModel<PropertyData> {
  get slug(): string {
    return 'properties';
  }

  get streetAddress(): string {
    return this.data.streetAddress;
  }

  get lastAttended(): Date | null {
    if (this.data.lastAttended) {
      return parseISO(this.data.lastAttended);
    }

    return null;
  }

  get annualServiceExpiry(): Date | null {
    if (this.data.annualServiceExpiry) {
      return parseISO(this.data.annualServiceExpiry);
    }

    return null;
  }

  get complianceColour(): string {
    if (this.data.compliant == 'COMPLIANT WITH NEW 2022 QLD LEGISLATION') {
      return colors.green.base;
    }
    if (this.data.compliant == 'COMPLIANT WITH CURRENT QLD LEGISLATION') {
      return colors.orange.base;
    }
    if (this.data.compliant == 'NOT COMPLIANT WITH CURRENT LEGISLATION') {
      return colors.red.base;
    }
    if (this.data.compliant == 'UPGRADED BY ANOTHER COMPANY') {
      return colors.blue.base;
    }

    return colors.grey.base;
  }

  get getPoolComplianceColour(): string {
    if (this.data.pool.compliance == 'COMPLIANT WITH QLD LEGISLATION') {
      return colors.green.base;
    }

    return colors.grey.base;
  }

  get showQuote(): boolean {
    return this.complianceColour != colors.green.base;
  }

  async downloadFile(type: 'quote' | 'compliance' | 'pool'): Promise<void> {
    await BaseModel.$api
      .get(
        `/agencies/${this.data.agency.id}/properties/${this.data.id}/${type}`,
        { responseType: 'blob' }
      )
      .then((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        window.open(link.href);
        // link.download = `${type.toUpperCase()} - ${this.data.streetAddress
        //   .replace('/', '')
        //   .replace('\\', '')}`;
        // link.click();
      });
  }

  getFileUrl(type: 'quote' | 'compliance'): string {
    return `/agencies/${this.data.agency.id}/properties/${this.data.id}/${type}`;
  }
}
