import { APIService } from './api';
import { AuthService } from './auth';
import { IServiceProvider } from './serviceProvider.types';

export const serviceProvider = (): IServiceProvider => {
  const api = new APIService();
  return {
    api,
    auth: new AuthService(api),
  };
};
