export * from './auth.types';
import { IAuthState } from './auth.types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
export * from './storage';

const state: IAuthState = {
  user: null,
  authenticated: false,
};

export const AuthStore = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
